import { render, staticRenderFns } from "./TicketsMobileCard.vue?vue&type=template&id=5cc823b4&"
import script from "./TicketsMobileCard.vue?vue&type=script&lang=js&"
export * from "./TicketsMobileCard.vue?vue&type=script&lang=js&"
import style0 from "./TicketsMobileCard.vue?vue&type=style&index=0&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
installComponents(component, {VBtn,VCard,VChip,VIcon,VSpacer,VToolbar})


/* hot reload */
if (module.hot) {
  var api = require("N:\\diglogix\\diglogix-web\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('5cc823b4')) {
      api.createRecord('5cc823b4', component.options)
    } else {
      api.reload('5cc823b4', component.options)
    }
    module.hot.accept("./TicketsMobileCard.vue?vue&type=template&id=5cc823b4&", function () {
      api.rerender('5cc823b4', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/workflow/ticket/TicketsMobileCard.vue"
export default component.exports