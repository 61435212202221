var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    { attrs: { flat: "", tile: "" } },
    [
      _c(
        "v-toolbar",
        { staticClass: "ticket-card-header", attrs: { flat: "" } },
        [
          _c(
            "a",
            {
              staticClass:
                "ticket-card-ticket-revision font-weight-bold primary--text",
              on: {
                click: function ($event) {
                  return _vm.$emit("onTicketDetails", _vm.item)
                },
              },
            },
            [
              _vm._v(
                " " +
                  _vm._s(_vm.item.center_ticket_id) +
                  "-" +
                  _vm._s(_vm.item.revision) +
                  " "
              ),
            ]
          ),
          _c("v-spacer"),
          _c(
            "v-chip",
            {
              staticClass: "px-3",
              attrs: {
                "x-small": "",
                "text-color": "white",
                color: _vm.priorityIconColor(_vm.item.priority),
              },
            },
            [_vm._v(_vm._s(_vm.item.priority) + " ")]
          ),
        ],
        1
      ),
      _c("table", { attrs: { id: "ticket-cards" } }, [
        _c("tr", [
          _c("td", { staticClass: "text-no-wrap" }, [_vm._v("Center:")]),
          _c("td", { staticClass: "text-truncate font-weight-bold" }, [
            _vm._v(" " + _vm._s(_vm.item.center_name) + " "),
          ]),
        ]),
        _c("tr", [
          _c("td", { staticClass: "text-no-wrap" }, [_vm._v("Address:")]),
          _c("td", { staticClass: "text-truncate font-weight-bold" }, [
            _vm._v(_vm._s(_vm.item.fullAddress)),
          ]),
        ]),
        _c("tr", [
          _c("td", { staticClass: "text-no-wrap" }, [_vm._v("Start Date:")]),
          _c("td", { staticClass: "font-weight-bold" }, [
            _vm._v(_vm._s(_vm.formatDate(_vm.item.start_date))),
          ]),
        ]),
        _c("tr", [
          _c("td", { staticClass: "text-no-wrap" }, [
            _vm._v("Expiration Date:"),
          ]),
          _c("td", { staticClass: "font-weight-bold" }, [
            _vm._v(
              _vm._s(
                _vm.item.center_name === "OHIO811"
                  ? "N/A"
                  : _vm.formatDate(_vm.item.expiration_date)
              )
            ),
          ]),
        ]),
        _c("tr", [
          _c("td", [
            _vm._v(
              _vm._s(_vm.supportsResponses ? "Response Status" : "Members") +
                ":"
            ),
          ]),
          _c(
            "td",
            [
              _c("v-spacer"),
              _vm.item.type !== "CNCL"
                ? _c(
                    "v-btn",
                    {
                      attrs: {
                        text: "",
                        small: "",
                        outlined: "",
                        color: "primary",
                        rounded: "",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.$emit("showStatusDialog", _vm.item)
                        },
                      },
                    },
                    [
                      _vm.supportsResponses
                        ? _c(
                            "div",
                            [
                              _c(
                                "v-icon",
                                {
                                  attrs: {
                                    left: "",
                                    "x-small": "",
                                    color:
                                      _vm.calculateResponsePercentage(_vm.item)
                                        .value === 100
                                        ? "green"
                                        : "red",
                                  },
                                },
                                [_vm._v(" mdi-circle ")]
                              ),
                              _vm._v(
                                " " + _vm._s(_vm.responseStatus(_vm.item)) + " "
                              ),
                            ],
                            1
                          )
                        : _c("div", [_vm._v("View Members")]),
                    ]
                  )
                : _vm._e(),
              _vm.item.type === "CNCL"
                ? _c(
                    "v-btn",
                    {
                      attrs: {
                        text: "",
                        small: "",
                        outlined: "",
                        rounded: "",
                        color: "primary",
                      },
                    },
                    [
                      _c(
                        "v-icon",
                        { attrs: { left: "", "x-small": "", color: "red" } },
                        [_vm._v(" mdi-circle ")]
                      ),
                      _vm._v(" " + _vm._s(_vm.$t("cancelled")) + " "),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }