<template>
  <v-card flat tile>
    <v-toolbar flat class="ticket-card-header">
      <!-- Hide this toolbar checkbox until feature is complete -->
      <!-- <div class="checkbox-container">
                <slot name="selectCheckbox" />                              
            </div> -->
      <a
        class="ticket-card-ticket-revision font-weight-bold primary--text"
        @click="$emit('onTicketDetails', item)"
      >
        <!-- <router-link
                    :to="ticketDetailsLink(item.tid)"
                > -->
        {{ item.center_ticket_id }}-{{ item.revision }}
        <!-- </router-link>                 -->
      </a>
      <v-spacer />
      <v-chip
        class="px-3"
        x-small
        text-color="white"
        :color="priorityIconColor(item.priority)"
        >{{ item.priority }}
      </v-chip>
      <!-- <router-link
                :to="ticketDetailsLink(item.tid)"
            >
                <v-chip
                    small
                    :color="statusChipColor"
                    outlined
                    :class="statusChipClass"
                >
                    {{ statusText }}
                </v-chip>
                <v-icon class="mr-2">
                    chevron_right
                </v-icon>
            </router-link> -->
    </v-toolbar>

    <table id="ticket-cards">
      <tr>
        <td class="text-no-wrap">Center:</td>
        <td class="text-truncate font-weight-bold">
          {{ item.center_name }}
        </td>
      </tr>
      <tr>
        <td class="text-no-wrap">Address:</td>
        <td class="text-truncate font-weight-bold">{{ item.fullAddress }}</td>
      </tr>
      <!-- <tr>
                <td class="text-no-wrap">Site Contact:</td>
                <td class="text-truncate font-weight-bold">{{ item.fullAddress }}</td>
            </tr>
            <tr>
                <td class="text-no-wrap">Contact Phone:</td>
                <td class="text-truncate font-weight-bold">{{ item.fullAddress }}</td>
            </tr> -->

      <tr>
        <td class="text-no-wrap">Start Date:</td>
        <td class="font-weight-bold">{{ formatDate(item.start_date) }}</td>
      </tr>
      <tr>
        <td class="text-no-wrap">Expiration Date:</td>
        <td class="font-weight-bold">{{ item.center_name === 'OHIO811' ? 'N/A' : formatDate(item.expiration_date) }}</td>
      </tr>

      <tr>
        <td>{{ supportsResponses ? "Response Status" : "Members" }}:</td>
        <td>
          <v-spacer />
          <v-btn
            v-if="item.type !== 'CNCL'"
            text
            small
            outlined
            color="primary"
            rounded
            @click="$emit('showStatusDialog', item)"
          >
            <div v-if="supportsResponses">
              <v-icon
                left
                x-small
                :color="
                  calculateResponsePercentage(item).value === 100
                    ? 'green'
                    : 'red'
                "
              >
                mdi-circle
              </v-icon>
              {{ responseStatus(item) }}
            </div>
            <div v-else>View Members</div>
          </v-btn>
          <v-btn
            v-if="item.type === 'CNCL'"
            text
            small
            outlined
            rounded
            color="primary"
          >
            <v-icon left x-small color="red"> mdi-circle </v-icon>
            {{ $t("cancelled") }}
          </v-btn>
        </td>
      </tr>
    </table>
  </v-card>
</template>
<script>
import MemberMixin from "@/mixins/MemberMixin";
export default {
  props: {
    item: {
      type: Object,
      default: () => {},
    },
    supportsResponses: {
      type: Boolean,
      default: false,
    },
    isSelected: {
      type: Boolean,
      default: false,
    },
  },
  mixins: [MemberMixin],
  data() {
    return {
      centers: [],
    };
  },
  computed: {
    selectedClass() {
      return this.isSelected ? " selected" : "";
    },
  },
  methods: {
    ticketDetailsLink(tid) {
      let link = "/tickets/details?tid=" + tid;
      if (this.$store.getters.forceOffline) {
        link = "/offline" + link;
      }
      return link;
    },
    formatDate(date) {
      date = new Date(date);
      const options = {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
      };
      return date.toLocaleDateString("en-US", options);
    },
    formatAddress(address, street, place, state, cross1, cross2) {
      // https://norfield.atlassian.net/browse/CWTM-902
      // If address then do "address street, place, state"
      // If address zero length string do "street & cross1, place, state"
      // If address zero length string and cross2 do "street between cross1 & cross2, place, state"
      if (address.length) {
        return address + " " + street + ", " + place + ", " + state;
      }
      if (cross1.length) {
        if (cross2.length) {
          // If address zero length string and cross2 do "street between cross1 & cross2, place, state"
          return `${street} between ${cross1} & ${cross2}, ${place}, ${state}`;
        } else {
          // If address zero length string do "street & cross1, place, state"
          return `${street} & ${cross1}, ${place}, ${state}`;
        }
      } else {
        return street + ", " + place + ", " + state;
      }
    },
    hasMembers(receipt) {
      if (receipt) {
        if (receipt.members) {
          return receipt.members.length > 0;
        }
      }
      return false;
    },
    responseStatus(ticket) {
      let result = this.calculateResponsePercentage(ticket);
      let responseStatusText = "";
      responseStatusText = result.responded + "/" + result.total + " Received";

      return responseStatusText;
    },
    statusIconColor(receipt, responses) {
      let responded = 0;
      let total = 0;
      if (responses) {
        responded = responses.length;
      }
      if (receipt?.members) {
        total = this.totalMembersToShow(receipt.members);
      }
      if (total !== 0 && responded === total) {
        return "green";
      } else {
        return "red";
      }
    },
    priorityIconColor(priority) {
      switch (priority) {
        case "RUSH":
          return "#fdd835";
        case "EMER":
          return "red";
        case "NONC":
          return "light-blue";
        default:
          return "grey";
      }
    },
  },
};
</script>
<style>
#ticket-cards .v-card {
  padding-bottom: 16px;
}
.status-background {
  background-color: #d0ceff;
}

#ticket-cards .checkbox-container .v-input--selection-controls {
  margin-top: 0 !important;
}
#ticket-cards .ticket-card-header {
  background-color: #f5f7fa;
}
#ticket-cards .ticket-card-header a {
  text-decoration: none;
}
#ticket-cards .ticket-card-ticket-revision a {
  color: #1976d2;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
}
#ticket-cards .ticket-card-header a .v-chip {
  cursor: pointer;
}
#ticket-cards .ticket-card-info {
  border-radius: 4px;
  border: 1px solid #e4e7ed;
  height: 28px;
  display: inline-block;
  min-width: 40px;
  margin-top: 16px;
  margin-bottom: 16px;
  margin-right: 8px;
  padding: 4px 8px;
  color: #35383f;
  font-size: 12px;
  background: #fff;
}
#ticket-cards table {
  font-size: 12px;
  width: 100%;
}
#ticket-cards table td {
  padding: 2px 0;
}
#ticket-cards table td:first-child {
  width: 25%;
  white-space: nowrap;
}
#ticket-cards table td:last-child {
  width: 75%;
}
</style>
